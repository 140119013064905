import { NavLink, useLocation } from '@remix-run/react'

import type { Route } from '~/services/routes.server'
import { classNames } from '~/utils/class-names'

export const styles = {
  nav: 'flex items-baseline gap-2 overflow-x-auto whitespace-nowrap',
  base: 'flex rounded-md px-3 py-2 leading-5 hover:bg-button-secondary-hover dark:hover:bg-zinc-700',
  active: 'bg-button-secondary font-medium text-brand-600 dark:bg-brand-500 dark:text-white',
}

export type NavProps = JSX.IntrinsicElements['nav'] & {
  routes: Route[]
  keepSearchParams?: boolean
  onClose?: () => void
}

export function NavItem({ routes, keepSearchParams, onClose }: NavProps) {
  const location = useLocation()
  return (
    <>
      {routes.map((item) => (
        <NavLink
          className={({ isActive }) =>
            classNames(styles.base, {
              [styles.active]: isActive,
            })
          }
          end={item.end}
          key={item.label}
          to={keepSearchParams ? `${item.path}${location.search}` : item.path}
          onClick={() => onClose?.()}
        >
          {item.label}
        </NavLink>
      ))}
    </>
  )
}

export function Nav({ routes, className, onClose, keepSearchParams }: NavProps) {
  return (
    <nav className={classNames(styles.nav, className)}>
      <NavItem keepSearchParams={keepSearchParams} routes={routes} onClose={onClose} />
    </nav>
  )
}
